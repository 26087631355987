
export default {
    SELECT_CONCEPT: 'select-concept',
    SELECT_PEOPLE: 'select-people',
    SELECT_CHILDREN: 'select-children',
    SELECT_CHILDREN_CHAIRS: 'select-children-chairs',
    SELECT_DATE: 'select-date',
    CONFIRM_DATE: 'confirm-date',
    SELECT_TIME: 'select-time',
    CONFIRM_TIME: 'confirm-time',
    SELECT_AREA: 'select-area',
    SELECT_AREA_AFTER_TIME: 'select-area-after-time',
    SELECT_SPECIAL_BOOKING_OPTIONS: 'select-special-booking-options',
    CONFIRM_AREA: 'confirm-area',
    CONTACT_INFORMATION: 'contact-information',
    BOOKING_COMPLETE: 'booking-complete',


    // Cancel Reservation sections
    BOOKING_INFORMATION: 'booking-information',
    CANCELLATION_COMPLETED: 'cancellation-completed'
}