export default {
    formatPrice(price, digits = 2, locale = 'da-dk') {
        return parseFloat(parseFloat(price).toFixed(digits)).toLocaleString(locale, { minimumFractionDigits: digits });
    },

    formatDatetime(date, locale = 'da-dk', options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' }) {
        return date.toLocaleDateString(locale, options);
    },    
}

export function addToObject(obj, key, value, index) {
    var temp = {};
    var i = 0;

    if (Object.keys(obj).length <= index) {
        temp = obj
        temp[key] = value
        return temp
    }

    for (var prop in obj) {
        if (obj.hasOwnProperty(prop)) {
            if (i === index && key && value) {
                temp[key] = value;
            }
            temp[prop] = obj[prop];
            i++;    
        }
    }

    // If no index, add to the end
    if (!index && key && value) {
        temp[key] = value;
    }

    return temp;
}
