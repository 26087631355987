import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta'
import { loadLanguageAsync } from '../i18n-setup.js'

Vue.use(VueRouter)
Vue.use(Meta)

const routes = [
  {
    path: '/onlinebooking',
    name: 'onlinebooking',
    component: () => import(/* webpackChunkName: "home" */ '../views/onlinebooking.vue'),
  },
  {
    path: '/',
    redirect: '/onlinebooking'
  }
]
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
    const lang = to.params.lang;
    //loadLanguageAsync(lang).then(() => next());
    loadLanguageAsync('da-dk').then(() => next());
});

export default router
