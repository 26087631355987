
export default {
    methods: {
        safeJsonParse(jsonString, defaultValue="") {
            try {
                return JSON.parse(jsonString)
            } catch (e) {
                return defaultValue
            }
        }
    }
}