import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from "@/store";


//import 'bootstrap/dist/css/bootstrap.min.css'
//import 'bootstrap/scss/bootstrap.scss';
import 'bootstrap-icons/font/bootstrap-icons.scss';
import 'bootstrap';


import router from './router'

//import VueI18n from 'vue-i18n'

Vue.config.productionTip = false
Vue.prototype.helper = require('./helper').default;
//Vue.use(VueI18n);
import './i18n-setup.js'
import { i18n } from './i18n-setup.js'

//window.emitter = new Vue();

/* // Ready translated locale messages
const messages = {
    en: {
      message: {
        hello: 'hello world'
      }
    },
    ja: {
      message: {
        hello: 'こんにちは、世界'
      }
    }
  }
  
  const i18n = new VueI18n({
    locale: 'dk',
    fallbackLocale: 'dk',
    messages, // set locale messages
  })

  const loadedLanguages = ['en'] */

new Vue({
    i18n,
    router,
    store,
    render: h => h(App)
}).$mount('#app')

