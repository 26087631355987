<template>

    <div 
        v-if="showModal"
    >
        <transition name="modal">
            <div class="modal-mask">
                <div class="modal-wrapper">
                    <div 
                        class="modal-dialog" 
                        role="document"
                    >
                        <div 
                            class="modal-content ui-dialog container"
                            style="height: auto;width: 300px;margin: 0 auto;position:relative;display: block;"
                        >

                            <div class="modal-header">
                                <div class="ui-dialog-titlebar ui-widget-header ui-corner-all ui-helper-clearfix">
                                    <span id="ui-id-1" class="ui-dialog-title">
                                        Advarsel
                                    </span>
                                    <button 
                                        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-icon-only ui-dialog-titlebar-close" 
                                        role="button" 
                                        aria-disabled="false" 
                                        title="close"
                                        @click="cancel"
                                    >
                                        <span class="ui-button-icon-primary ui-icon ui-icon-closethick"></span>
                                    </button>
                                </div>
                            </div>

                            <div class="modal-body">
                                <div 
                                    id="alert_dialog" 
                                    class="ui-dialog-content ui-widget-content"
                                >
                                    <p class="content-body font_awsome_color">{{ message }}</p>
                                </div>
                            </div>

                            <div class="modal-footer ui-dialog-buttonpane ui-widget-content ui-helper-clearfix">
                                <div class="ui-dialog-buttonset">
                                    <button 
                                        type="button" 
                                        class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
                                        role="button" 
                                        aria-disabled="false"
                                        @click="okClicked"
                                    >
                                        <span class="ui-button-text">
                                            OK
                                        </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>


<script lang="js">
export default {
    name: 'AlertDialog',
    data() {
        return  {

        }
    },
    props: ['showModal', 'message'],
    methods: {
        cancel() {
            this.$emit('close')
        },
        okClicked() {
            this.$emit('close')
        }
    }

}
</script>

<style scoped>


.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: flex;
    transition: opacity .3s ease;


    justify-content: center;
    align-items: center; 
}

.ui-widget-header {
    border-color: rgb(72, 78, 81);
    background-image: url(blob:https://bord-booking.dk/ab042e82-d5fc-464e-ae2a-4c3993a34e20);
    background-color: rgb(53, 57, 59);
    color: rgb(211, 207, 201);
}

.ui-dialog .ui-dialog-titlebar {
    padding: .4em 1em;
    position: relative;
}

.ui-dialog {
    position: absolute;
    top: 0;
    left: 0;
    padding: .2em;
    outline: 0;
}

.ui-dialog.container {
    background-image: initial;
    background-color: rgb(92, 99, 103);
}

.ui-state-default .ui-icon {
    background-image: url(https://bord-booking.dk/defaultRes/online_booking/images/ui-icons_cccccc_256x240.png);
}

.ui-dialog .ui-dialog-content {
    position: relative;
    border: 0;
    padding: .5em 1em;
    background: none;
    overflow: auto;
}

.font_awsome_color {
    color: rgb(232, 230, 227) !important;
}

.ui-widget-content {
    border-color: rgb(62, 68, 70) !important;
    background-color: rgb(92, 99, 103);
}

.ui-dialog .ui-dialog-content {
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    background-image: none;
    background-color: initial;
}

.ui-dialog .ui-dialog-titlebar-close {
    position: absolute;
    right: .3em;
    top: 50%;
    width: 21px;
    margin: -10px 0 0 0;
    padding: 1px;
    height: 20px;
}

.ui-dialog .ui-dialog-buttonpane {
    text-align: left;
    border-width: 1px 0 0 0;
    background-image: none;
    margin-top: .5em;
    padding: .3em 1em .5em .4em;
}

.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    float: right;
}

.ui-icon-closethick {
    background-position: -96px -128px;
}



</style>