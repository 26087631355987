<template>
    <div id="child_buttons">
        <p>{{ message }}</p>
        <button
            class="button_persons ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
            v-for="(number, index) in data"
            :key="index"
            @click="nChildrenSelected(number)"
        >
            <span class="ui-button-text">{{ number }}</span>
        </button>
    </div>
</template>

<script lang="js">

export default {
    name: 'SelectNumberOfChildren',
    data() {
        return {

        }
    },
    props: ['data', 'restaurantData', 'dataSelected'],
    computed: {
        message() {
            const jsonObject = this.restaurantData.texts.vaelg_antal_boern_beskrivelse
            if (this.restaurantData.texts == undefined || !jsonObject) {
                return ''
            }
            const obj = JSON.parse(jsonObject)
            const currentLang = this.lang.name
            return obj[currentLang] || ''
        },
        lang() {
            return this.$store.state.lang
        }
    },
    methods: {
        nChildrenSelected(number) {
            this.dataSelected({
                'numberOfChildren': number
            })
        }
    }
}
</script>