<template>
    <div>
        <span 
        >
            <input 
                class="ui-helper-hidden-accessible"        
                type="checkbox"
                :name="text"
                :id="id"
                :checked="value"
                @change="onChange"
            />
            <label
                :class="labelClass"
                :for="id"
            >
                <span class="ui-button-text">
                    <span class="text">{{ lang.company[translatedText] }}</span>
                    <div :style="iconDisplay">
                        <span :class="iconClass"></span>
                    </div>
                </span>
            </label>
        </span>
    </div>
</template>

<script lang="js">

export default {
    name: 'CustomCheckboxButton',
    data() {
        return {
            
        }
    },
    props: ['text', 'translatedText', 'id', 'name', 'value'],
    computed: {
        isChecked() { return this.value },
        onlyIcon() { return this.translatedText === undefined || this.translatedText === '' },
        labelClass() {
            const unSelectedClass = 'ui-button ui-widget ui-state-default ui-button-text-only ui-corner-right ui-corner-left'
            return (this.isChecked) ? unSelectedClass + ' ui-state-active' : unSelectedClass
        },
        iconDisplay() {
            if (this.onlyIcon) {
                return 'display:inline-block;'
            }else {
                return (this.isChecked) ? 'display:inline-block;' : 'display:none;'
            }
        },
        iconClass() {
            if (this.isChecked) {
                return 'ui-icon ui-icon-check'
            } else {
                return (this.onlyIcon) ? 'ui-icon ui-icon-minus' : 'ui-icon ui-icon-check'
            }
        },
        lang() { return this.$store.state.lang },
    },
    methods: {
        onChange() {
            const newValue = !this.value
            this.$emit('input', newValue);
        }
    }, 
}
</script>