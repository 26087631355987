<template>
    <div>
        <p class="confirmText" v-html="formattedMessage">
        </p>

        <span v-if="data.remove_continue_btn != '1'"
            class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="dateConfirmed"
        >
            <span class="ui-button-text">{{ lang.company['videre'] }}</span>
        </span>
    </div>
</template>

<script lang="js">

export default {
    name: 'ConfirmDate',
    data() {
        return {

        }
    },
    props: ['data', 'dataSelected'],
    computed: {
        formattedMessage() {
            return this.data.comment.replace(/\n/g, '<br>')
        },
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        dateConfirmed() {
            this.dataSelected({
                'title': 'Date Confirmed',
                'confirmed': true,
                'comment': this.formattedMessage
            })
        }
    }
}
</script>