<template>
    <div>
        <div v-html="confirmAreaHTML">
            
        </div>

        <span 
            class="button_confirmOptions ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="dateConfirmed"
        >
            <span class="ui-button-text">{{ lang.company['videre'] }}</span>
        </span>
    </div>
</template>

<script lang="js">

export default {
    name: 'ConfirmArea',
    data() {
        return {

        }
    },
    props: ['data', 'dataSelected'],
    computed: {
        confirmAreaHTML() {
            return this.data.confirmAreaHTML
        },
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        dateConfirmed() {
            this.dataSelected({
                'title': 'Area Confirmed',
                'confirmed': true
            })
        }
    }, 
}
</script>