<template>
    <div 
        :id="'option_' + id"     
        :style="divStyle"
    >
        <b>
            <p 
                :id="'p' + id" 
                class="question_header"
            >
                {{ name }}
            </p>
        </b>
        <p class="question_description" v-html="description" />
        <div class="radio ui-buttonset">
            <CustomRadioButton
                style="display:inline-block;"
                v-for="input in inputs"
                :key="input.id"
                :id="input.id"
                :text="input.text"
                :translatedText="lang.company[input.translatedText]"
                v-model="selectedOption"
            />
            <div
                :id="'radio' + id + '_error'"
                class="error"
                :style="errorStyle"
            >
                <br>{{ lang.company['husk_at_vaelge_ja_eller_nej'] }}
            </div>
            <hr>
        </div>
    </div>
</template>

<script lang="js">

import CustomRadioButton from './customButtons/CustomRadioButton.vue';


export default {
    name: 'CheckType',
    data() {
        return {
            selectedOption: '',
        }
    },
    components: {
        'CustomRadioButton': CustomRadioButton
    },
    props: ['id', 'name', 'description', 'inputs', 'divStyle', 'errorStyle', 'defaultValue', 'dataChanged'],
    watch: {
        selectedOption(newValue, oldValue) {
            this.dataChanged({
                'id': this.id,
                'selectedOption': newValue
            })
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    mounted() {
        this.selectedOption = this.defaultValue
    },
}
</script>