<template>
    <div>
        <div 
            class ="ONE_CHECK"
            :id="'option_' + id"
            :style="divStyle"
        >
            <b>
                <p
                    :id="'p' + id" 
                    class="question_header"
                >
                    {{ name }}
                </p>
            </b>
            <p 
                class="question_description"
            >
                {{ description }}
            </p>

            <div class="checkbox ui-buttonset">
                <CustomCheckboxButton
                    :id="id"
                    :name="id"
                    v-model="isOneCheckChecked"
                />
            </div>
            <hr>
        </div>
    </div>
</template>

<script lang="js">


import CustomCheckboxButton from '../customButtons/CustomCheckboxButton.vue'


export default {
    name: 'OneCheckType',
    data() {
        return {
            'isOneCheckChecked': false
        }
    },
    props: ['id', 'name', 'description', 'divStyle', 'dataChanged'],
    watch: {
        isOneCheckChecked(newValue, oldValue) {
            console.log(`newValue=${newValue}, oldValue=${oldValue}`)
            this.dataChanged({
                'id': this.id,
                'selectedOption': newValue
            })
        }
    },
    components: {
        'CustomCheckboxButton': CustomCheckboxButton
    }
    
}
</script>