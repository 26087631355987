<template>
    <div>
        <h3>{{ lang.company['rate_usability_header'] }}</h3>
        <form v-if="isFormVisible" onsubmit="return false" class="rating_form">
            <fieldset>
                <span class="star-cb-group">
                    <input type="radio" id="rating-5" class="closewhy" name="rating" value="5" @click="closeWhy(5)" />
                    <label for="rating-5" id="rating-5-label">5</label>

                    <input type="radio" id="rating-4" class="openwhy" name="rating" value="4" @click="openWhy(4)" />
                    <label for="rating-4">4</label>
                    
                    <input type="radio" id="rating-3" class="openwhy" name="rating" value="3" @click="openWhy(3)" />
                    <label for="rating-3" id="rating-3-label">3</label>

                    <input type="radio" id="rating-2" class="openwhy" name="rating" value="2" @click="openWhy(2)" />
                    <label for="rating-2">2</label>

                    <input type="radio" id="rating-1" class="openwhy" name="rating" value="1" @click="openWhy(1)" />
                    <label for="rating-1">1</label>
                </span>
            </fieldset>

            <div v-if="isWhyVisible" class="input_container rating_comment">
                <label>{{ lang.company['what_can_we_do_better'] }}</label>
                <div class="wrap-input100 validate-input">
                    <textarea 
                        id="rating_comment_box"
                        v-model="comment"
                        class="input100" 
                        name="message"
                        placeholder="Ris eller ros tak! :)"
                    >
                    </textarea>
                    <span class="focus-input100"></span>
                    <label class="label-input100 rs1" for="message">
                        <i class="far fa-comment"></i>
                    </label>
                </div>
            </div>

            <span 
                v-if="isSendButtonVisible"
                class="select_concept ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
                role="button" 
                aria-disabled="false"
                @click="submitReview"
            >
                <span class="ui-button-text">Send</span> 
            </span>

        </form>

        <h3 
            v-if="isThanksForRatingVisible"
            id="thanks_rate_usability">{{ lang.company['thank_you_for_rating'] }}
        </h3>
    </div>
</template>

<script lang="js">


export default {
    name: 'Rating',
    data() {
        return {
            isFormVisible: true,
            isWhyVisible: false,
            isSendButtonVisible: false,
            isThanksForRatingVisible: false,
            rating: 0,
            comment: ""
        }
    },
    props: ['bookingIdentifierNo', 'dataSelected'],
    computed: {
        lang() {
            return this.$store.state.lang
        }
    },
    methods: {
        openWhy(number) {
            this.rating = number
            this.isWhyVisible = true
            this.isSendButtonVisible = true
        },
        closeWhy(number) {
            this.rating = number
            this.isWhyVisible = false
            this.isSendButtonVisible = true
        },
        submitReview() {
            if (this.comment.length < 0 || this.rating < 1 || this.rating > 5) {
                console.log("Something went wrong! Data is invalid")
                return
            }

            this.dataSelected({
                'rating': this.rating,
                'comment': this.comment,
                'BookingIdentifierNo': this.bookingIdentifierNo
            })

            this.isFormVisible = false
            this.isWhyVisible = false
            this.isSendButtonVisible = false
            this.isThanksForRatingVisible = true
        },
    }
}
</script>
