class Section {

    constructor({id, defTitleKey, lang,  data = {}, selectedData = {}}) {
        this.id = id
        this.defTitleKey = defTitleKey
        this.title = lang === undefined ? defTitleKey : lang.company[defTitleKey] // remove this later
        this.data = data
        this.selectedData = selectedData
        this.lang = lang
    }

    reset() {
        this.title = this.lang.company[this.defTitleKey]
        this.data = {}
        this.selectedData = {}
    }

    // This function should be implemented in child classes 
    setUserSelectedData({data}) {
        return true
    }

    requestDataFromServer() {

    }

    // This function should be implemented in child classes 
    parseDataFromServer({data}){
        this.data = data
    }


    // Based on selectedData, validate data from server or locally
    validateData() {
        return {'isValid': true, 'error': ''} // by default sections are valid
    }

    // Assuming when language update happens, page refresh will happen as well.
    // So, title will be reseted to default as well
    updateLang({lang}) {
        this.lang = lang
        this.title = this.lang.company[this.defTitleKey]
    }
}


class SelectConceptSection extends Section {
    constructor({lang}) {
        super({id: 'select-concept', defTitleKey: 'select_concept',lang: lang})
    }

    setUserSelectedData({data}) {
        // console.log(data);
        this.selectedData = {
            "concept_name": data.concept_name,
            "concept_id": data.concept_id
        }
        var newTitle = this.selectedData.concept_name
        this.title = newTitle
        this.isConceptSelected = data.concept_id != 0
        return true
    }

    parseDataFromServer({data}) {
        // console.table(data);
        if (Object.keys(data).length == 0) {
            this.data = { 'noContent': true }
        } else {
            this.data = data
        }
    }
}
class SelectPeopleSection extends Section {
    constructor({lang}) {
        super({id: 'select-people', defTitleKey: 'vaelg_antal_personer',lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            "numberOfPeople": data.numberOfPeople
        }

        const numberOfPeople = this.selectedData.numberOfPeople
        var newTitle = `${numberOfPeople} ${ numberOfPeople == 1 ? this.lang.company.person : this.lang.company.persons }`
        this.title = newTitle

        return true
    }
}

class SelectChildrenSection extends Section {
    constructor({lang}) {
        super({id: 'select-children', defTitleKey: 'vaelg_antal_boern', lang: lang})   
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            "numberOfChildren": data.numberOfChildren
        }
        const strKey = this.selectedData.numberOfChildren == 1 ? "barn" : "boern"
        this.title = `${this.selectedData.numberOfChildren} ${this.lang.company[strKey]}`

        return true
    }
}

class SelectChildrenChairsSection extends Section {
    constructor({lang}) {
        super({id: 'select-children-chairs', defTitleKey: 'vaelg_antal_boernestole', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            "numberOfChildrenChairs": data.numberOfChildrenChairs
        }
        const strKey = this.selectedData.numberOfChildrenChairs == 1 ? "barnestol" : "boernestole"
        this.title = `${this.selectedData.numberOfChildrenChairs} ${this.lang.company[strKey]}`

        return true
    }
}

class SelectDateSection extends Section {
    constructor({lang}) {
        super({id: 'select-date', defTitleKey: 'vaelg_dato', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            "date": data.date,
            "titleDate": data.titleDate,
            "remove_continue_btn": data.remove_continue_btn,
            "comment": data.comment
        }
        this.title = this.selectedData.titleDate
        return true
    }
} 

class ConfirmDateSection extends Section {
    constructor({lang}) {
        super({id: 'confirm-date', defTitleKey: 'godkend_dato', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            "title": data.comment,
            "confirmed": data.confirmed
        }
        this.title = this.selectedData.title
        return this.selectedData.confirmed
    }
}


class SelectTimeSection extends Section {
    constructor({lang}) {
        super({id: 'select-time', defTitleKey: 'vaelg_tidspunkt', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            "time": data.time,
            "reservationLength": data.reservationLength,
            "message": data.message,
            "availableLengths": data.availableLengths
        }
        this.title = this.selectedData.time
        return true
    }
}

class ConfirmTimeSection extends Section {
    constructor({lang}) {
        super({id: 'confirm-time', defTitleKey:'godkend_tidspunkt', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            'message': data.message,

            // Only exists if user has option to choose which time he wants
            'time': data.time,
            'title': data.title,
            'mesageToGuest': data.mesageToGuest ,
            'reservationLength': data.reservationLength
        }
        this.title = this.selectedData.title
        return true
    }
}


class SelectAreaSection extends Section {
    constructor({lang}) {
        super({id: 'select-area', defTitleKey: 'vaelg_omraade', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            "title": data.title,
            "area": data.area,
            "area_confirm_text": data.area_confirm_text
        }
        this.title = this.selectedData.title
        
        return true
    }
}

class SelectAreaAfterTimeSection extends Section {
    constructor({lang}) {
        super({id: 'select-area-after-time', defTitleKey: 'vaelg_omraade', lang: lang})   
    }

    setUserSelectedData({data}) {
        this.selectedData = data
        this.selectedData = {
            'title': data.area,
            'area': data.area,
            'area_confirm_text': data.area_confirm_text
        }
        this.title = this.selectedData.title
        return true
    }
}

class ConfirmAreaSection extends Section {
    constructor({lang}) {
        super({id: 'confirm-area', defTitleKey: 'godkend_omraade', lang: lang})
    }

    setUserSelectedData({data}) {
        if (data.hasOwnProperty('noContent')) {
            this.selectedData = data
        }
        this.title = this.lang.company['area_accepted']
    }

    parseDataFromServer({data}) {
        if (Object.keys(data).length == 0) {
            this.data = { 'noContent': true }
        } else {
            this.data = data
        }
    }
}

class SelectSpecialBookingOptionsSection extends Section {
    constructor({lang}) {
        super({id: 'select-special-booking-options', defTitleKey: 'vaelg_specielle_oensker', lang: lang})
    }

    setUserSelectedData({data}) {
        if (data.hasOwnProperty('noContent')) {
            this.selectedData = data
        } else {
            this.selectedData = {
                "title": data.title,
                "data": data.data
            }
        }
        
        this.title = this.selectedData.title
        return true
    }

    parseDataFromServer({data}) {
        if (Object.keys(data).length == 0) {
            this.data = { 'noContent': true }
        } else {
            this.data = data
        }
    }
}

class ContactInformationSection extends Section {
    constructor({lang}) {
        super({id: 'contact-information', defTitleKey: 'contactinfo', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            'name': data.name,
            'email': data.email,
            'mobile': data.mobile,
            'sendEmail': data.sendEmail,
            'sendSms': data.sendSms,
            'comment': data.comment
        }
        this.title = `${data.name}, ${data.mobile}`
        return true
    }

    validateData({data}) {
        if (data === undefined) {
            return {'isValid': false, 'error': ''}
        }
        if (data.hasOwnProperty('code')) {
            const code = data['code']
            if (code === 'OK') {
                const bookingID = data['BookingIdentifierNo']
                this.data.bookingID = bookingID
                return {'isValid': true, 'error': ''}
            }else if (code === 'NOT_OK'){
                return {'isValid': false, 'error': data['message']}
            }
        }
        return {'isValid': false, 'error': ''}
    }
}

class BookingCompleteSection extends Section {
    constructor({lang}) {
        super({id: 'booking-complete', defTitleKey: '', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = data
        return true
    }

    validateData({data}) {
        if(data === 'OK') {
            return {'isValid': true, 'error': ''}
        }

        return {'isValid': false, 'error': data}
    }

    parseDataFromServer({data}) {
        this.data = data
        this.title = this.lang.company['gennemfoert']
    }
}

class BookingInformationSection extends Section {
    constructor({lang}) {
        super({id: 'booking-information', defTitleKey: 'indtast_bookingoplysninger', lang: lang})
    }

    setUserSelectedData({data}) {
        this.selectedData = {
            'bookingNumber': data.bookingNumber,
            'mobileNumber': data.mobileNumber
        }
        this.title = `${data.mobileNumber}, ${data.bookingNumber}`
        return true
    }

    validateData({data}) {
        console.log(data)
        if (data == undefined) {
            let errorMessage = this.lang.company['ugyldig_bookingnummer_eller_telefon']
            return {'isValid': false, 'error': errorMessage}
        }else if (data === true) {
            return {'isValid': true, 'error': ''}
        }else {
            let errorMessage = this.lang.company['ugyldig_bookingnummer_eller_telefon']
            return {'isValid': false, 'error': errorMessage}
        }
    }
}

class CancellationCompleSection extends Section {
    constructor({lang}) {
        super({id: 'cancellation-completed', defTitleKey: 'afbestilling_gennemfoert', lang: lang})
    }

    setUserSelectedData({data}) {
        // TODO: not implemented
    }
}


module.exports = {
    SelectConceptSection, 
    SelectPeopleSection, 

    SelectChildrenSection,

    SelectChildrenChairsSection,

    SelectDateSection,
    ConfirmDateSection,

    SelectTimeSection,
    ConfirmTimeSection,

    SelectAreaSection,
    SelectAreaAfterTimeSection,
    ConfirmAreaSection,

    SelectSpecialBookingOptionsSection,

    ContactInformationSection,
    BookingCompleteSection,

    BookingInformationSection,

    CancellationCompleSection
}