<template>
    <div>
        <p v-html="message"></p>
        <Rating
            :dataSelected="dataSelected"
            :bookingIdentifierNo="bookingIdentifierNo"
        />
        <div 
                v-if="error !== ''"
                class="error" 
                style="display: block;"
            >
                <br>
                {{ error }}
            </div>
    </div>
    
</template>

<script lang="js">


import Rating from '../templates/Rating.vue';

export default {
    name: 'BookingComplete',
    data() {
        return {
            bookingIdentifierNo: ''
        }
    },
    props: ['data', 'dataSelected', 'error'],
    components: {
        'Rating': Rating
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        message() {
            let translatedMessage = this.lang.company['reservation_gennemfoert']
            this.bookingIdentifierNo = this.data.bookingID
            translatedMessage = translatedMessage.replace("{bookingid}", this.bookingIdentifierNo);
            return translatedMessage
        }
    },
    methods: {

    },
}
</script>


<style>
    
</style>