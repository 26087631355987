<template>
    <div id="child_chair_buttons">
        <p>{{ lang.company['vaelg_antal_boernestole_beskrivelse'] }}</p>
        <button
            class="button_persons ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
            v-for="(number, index) in data"
            :key="index"
            @click="nChildrenChairsSelected(number)"
        >
        <span class="ui-button-text">{{ number }}</span>
        </button>
    </div>
</template>

<script lang="js">

export default {
    name: 'SelectNumberOfChildrenChairs',
    data() {
        return {

        }
    },
    props: ['data', 'dataSelected'],
    computed: {
        lang() {
            return this.$store.state.lang
        }
    },
    methods: {
        nChildrenChairsSelected(number) {
            this.dataSelected({
                'numberOfChildrenChairs': number
            })
        }
    }
}
</script>