

const weekdays = [
    "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"
];

let monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
];

class MyDate {

    constructor(year, month, day) {
        this.year = Number(year)
        this.month = Number(month)
        this.monthIndex = Number(month-1)
        this.day = Number(day)


        const date = new Date(this.year, this.monthIndex, this.day)
        const dayOfTheWeek = date.getDay()
        this.dayOfTheWeekIndex = (dayOfTheWeek == 0) ? 6 : (dayOfTheWeek - 1) % 7 // Make monday first day of the week
    }

    static convertFromDate(date) {
        const year = date.getFullYear()
        const month = date.getMonth()+1
        const day = date.getDate()
        return new MyDate(year, month, day)
    }

    static convertFromString({dateStr, separator}) {
        const dateValuesArray = dateStr.split(separator)
        return new MyDate(
            dateValuesArray[0],
            dateValuesArray[1],
            dateValuesArray[2]
        )
    }

    isToday() {
        const currentDate = new Date()
        return currentDate.getFullYear() == this.year && 
               currentDate.getMonth() == this.monthIndex && 
               currentDate.getDate() == this.day
    }

    toFormattedString(separator="-") {
        function numberTo2DigitString(number) {
            return (number < 10) ? `0${number}` : `${number}`
        }
        const yearStr = numberTo2DigitString(this.year)
        const monthStr = numberTo2DigitString(this.month)
        const dayStr = numberTo2DigitString(this.day)
        return `${yearStr}${separator}${monthStr}${separator}${dayStr}`
    }

    toString() {
        const dayString = weekdays[this.dayOfTheWeekIndex]
        return `${dayString}, ${this.day}-${this.month}-${this.year}. DayOfTheWeekIndex=${this.dayOfTheWeekIndex}`
    }

}

class MyMonth {
    constructor({year, month, startDay=1, lastDay=undefined}) {
        this.year = Number(year)
        this.month = Number(month)
        this.monthIndex = Number(month-1)
        this.days = []
        this.startDay = (startDay < 0 || startDay > 31) ? 1 : Number(startDay)
        
        const lastDayOfTheMonth = this.getLastDateOfTheMonth().getDate()
        var toDay = (lastDay==undefined) ? lastDayOfTheMonth : Math.min(lastDay, lastDayOfTheMonth)

        for (let day = this.startDay; day <= toDay; day++) {
            const newDate = new MyDate(this.year, this.month, day)
            this.days.push(newDate)
        }
    }

    getLastDateOfTheMonth() {
        const newDate = new Date(this.year, this.monthIndex+1 , 0); 
        return newDate
    }

    getName() {
        return monthNames[this.monthIndex]
    }

    getListOfWeeks() {
        let weeks = []
        weeks.push(Array.from({length:7}))

        for (let day of this.days) {
            if (day.dayOfTheWeekIndex == 0) {
                weeks.push(Array.from({length:7})) // create a new weeks
            }
            weeks[weeks.length-1][day.dayOfTheWeekIndex] = day
        }

        return weeks
    }

    toString() {
        return `MyMonth: ${monthNames[this.monthIndex]} ${this.year}, has ${this.days.length}. 
            Starts in ${this.days[0].toString()}, ending in ${this.days[this.days.length-1].toString()}`
    }
}

class MyYear {
    constructor({year, startMonth = 1, startDay = 1, lastMonth=undefined, lastDay=undefined}) {
        this.year = Number(year)
        this.startMonth = (startMonth < 0 || startMonth > 12) ? 1 : Number(startMonth)
        this.startMonthIndex = this.startMonth-1
        this.startDay = (startDay < 0 || startDay > 31) ? 1 : Number(startDay)
        this.months = []

        var toMonth = (lastMonth == undefined) ? 12 : Number(lastMonth)

        var day = this.startDay
        for (let month = this.startMonth; month <= toMonth; month++) {
            const newMonth = new MyMonth({
                year:this.year, 
                month:month, 
                startDay:day, 
                lastDay:(month==toMonth) ? lastDay : undefined
            })
            this.months.push(newMonth)
            day = 1

        }
    }

    toString() {
        const lastMonth = this.months[this.months.length-1]
        const lastDay = lastMonth.days[lastMonth.days.length-1]
        const firstDay = this.months[0].days[0]
        return `${this.year} starts in ${firstDay.toString()}, ends in ${lastDay.toString()}`
    }
}

class CalendarGenerator {
    constructor(startDate, endDate) {
        this.startDate = startDate
        this.endDate = endDate
        this.years = []
        
        for (let year=startDate.year; year<=endDate.year; year++) {
            const startMonth = (year==startDate.year) ? startDate.month : undefined
            const lastMonth = (year==endDate.year) ? endDate.month : undefined

            const myYear = new MyYear({
                year: year,
                startMonth: startMonth,
                lastMonth: lastMonth
            })
            this.years.push(myYear)
        }
    }

    getAllDates() {
        let dates = []
        for (let year of this.years) {
            for (let month of year.months) {
                for (let day of month.days) {
                    dates.push(day)
                }
            }
        }
        return dates
    }

    getMonths() {
        let months = []
        for (let year of this.years) {
            for (let month of year.months) {
                months.push(month)
            }
        }
        return months
    }
}

module.exports = {
    MyDate, 
    CalendarGenerator
}