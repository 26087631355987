<template>
    <div class="marketing_signup_form">
            <input 
                class="marketing_checkbox"        
                type="checkbox"
                :name="text"
                :id="id"
                :checked="value"
                @change="onChange"
            />
            <label
                :class="labelClass"
                :for="id"
            >
               
                   {{ lang.company[translatedText] }}
                        
                
            </label>
    </div>
</template>

<script lang="js">

export default {
    name: 'MarketingCheckbox',
    data() {
        return {
            
        }
    },
    props: ['text', 'translatedText', 'id', 'name', 'value'],
    computed: {
        isChecked() { return this.value },
        onlyIcon() { return this.translatedText === undefined || this.translatedText === '' },
        labelClass() {
            const unSelectedClass = ''
            return (this.isChecked) ? unSelectedClass + ' ' : unSelectedClass
        },
        iconDisplay() {
            if (this.onlyIcon) {
                return 'display:inline-block;'
            }else {
                return (this.isChecked) ? 'display:inline-block;' : 'display:none;'
            }
        },
        iconClass() {
            if (this.isChecked) {
                return 'ui-icon ui-icon-check'
            } else {
                return (this.onlyIcon) ? 'ui-icon ui-icon-minus' : 'ui-icon ui-icon-check'
            }
        },
        lang() { return this.$store.state.lang },
    },
    methods: {
        onChange() {
            const newValue = !this.value
            this.$emit('input', newValue);
        }
    }, 
}
</script>