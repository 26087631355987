<template>
    <div>
        <!-- tak_for_afbestilling -->
        <p v-html="cancelMessage"></p>
    </div>
</template>

<script lang="js">

export default {
    name: 'CancellationCompleted',
    data() {
        return {
            
        }
    },
    props: ['restaurantID'],
    methods: {

    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        cancelMessage() {
            let translatedMessage = this.lang.company['tak_for_afbestilling']
            if (translatedMessage === undefined) {
                translatedMessage = ""
            } else {
                translatedMessage = translatedMessage.replace("{ID}", this.restaurantID);
            }
            return translatedMessage
        }
    }
}
</script>


<style>
    
</style>