<template>
    <div>
        <div
            class="option_buttons"
            v-for="(option, key) in filteredData"
            :key="key"
        >
            <div v-if="option.type === 'ONE_CHECK'">
                <OneCheckType
                    :id="option.id"
                    :name="option.name"
                    :description="option.description"
                    :divStyle="option.style"
                    :dataChanged="dataChanged"
                />
            </div>


            <div v-else-if="option.type === 'CHECK'">
                <CheckType
                    :id="option.id"
                    :name="option.name"
                    :description="option.description"
                    :inputs="option.inputs"
                    :divStyle="option.style"
                    :errorStyle="option.errorStyle"
                    :defaultValue="option.defaultValue"
                    :dataChanged="dataChanged"
                />
            </div>


            <div v-else-if="option.type === 'TEXT'">
                <div
                    :id="'option_' + option.id"
                    :style="option.style"
                >
                    <b>
                        <p 
                            :id="'p' + option.id" 
                            class="question_header"
                        >
                            {{ option.name }}
                        </p>
                    </b>
                    <p class="question_description">{{ option.description }}</p>
                    <input 
                        maxlength = "100" 
                        class ="cos_input_field"
                        :id="option.id"
                        :name="'radio' + option.id"
                        type ="text"
                        v-model="selectedOptions[option.id]"
                    >
                    <div
                        :id="'radio' + option.id + '_error'"
                        class="error"
                        :style="option.errorStyle"
                    >
                        <br>{{ lang.company['husk_at_vaelge_ja_eller_nej'] }}
                    </div>
                    <hr>
                </div>
                
            </div>


            <div v-else>
                <p>Unknown type {{ option.type }}</p>
            </div>
        </div>

        <!-- button_confirmOptions ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only -->
        <span 
            class="button_confirmOptions ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="nextButtonClicked"
        >
            <span class="ui-button-text">{{ lang.company['videre'] }}</span>
        </span>
    </div>
</template>

<script lang="js">

import CheckType from '../CheckType.vue';
import OneCheckType from '../checkTypes/OneCheckType.vue';


export default {
    name: 'SelectSpecialBookingOptions',
    data() {
        return {
            'filteredData': {},
            'selectedOptions': {},
            'textInput': ''
        }
    },
    components: {
        'CheckType': CheckType,
        'OneCheckType': OneCheckType,
    },
    props: ['data', 'dataSelected'],
    watch: {
        data (val, oldVal) {
            if (val !== oldVal) {
                this.formatData()
                this.$forceUpdate()
            }
        }
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        formatKey(data) {
            let checkID = data.id
            let errorStyle = data.errorStyle
            return checkID + errorStyle
        },
    },
    methods: {
        formatData() {
            for (const i in this.data) {
                const option = this.data[i]
                this.parseOption(option)
            }
        },
        parseOption(option) {
            var style = ''

            var defaultValue = this.getOptionDefaultValue(option)
            var selectedOption = this.selectedOptions.hasOwnProperty(option.CheckId) ? this.selectedOptions[option.CheckId] : defaultValue

            if (option.ShowIfYes > 0) {
                const isParentOptionSelected = this.selectedOptions[option.ShowIfYes] === 'yes'
                if (isParentOptionSelected) {
                    style = ''
                    this.selectedOptions[option.CheckId] = selectedOption
                } else {
                    style = 'display:none;'
                    delete this.selectedOptions[option.CheckId]
                }
            } else if (option.ShowIfNo > 0) {
                const isParentOptionNotSelected = this.selectedOptions[option.ShowIfNo] === 'no'
                if (isParentOptionNotSelected) {
                    style = ''
                    this.selectedOptions[option.CheckId] = selectedOption
                }else {
                    style = 'display:none;'
                    delete this.selectedOptions[option.CheckId]
                }
            } else {
                // Here: option is mandatory and is not based on another option
                if (!this.selectedOptions.hasOwnProperty(option.CheckId)) {
                    this.selectedOptions[option.CheckId] = selectedOption
                }
            }

            this.filteredData[option.CheckId] = {
                'id': option.CheckId,
                'name': option.Name,
                'description': option.Description,
                'style': style,
                'type': option.Type,
                'errorStyle': 'display: none;',
                'defaultValue': defaultValue,
                'inputs': [
                    {
                        'id': 'y' + option.CheckId,
                        'text': 'yes',
                        'translatedText': 'ja_tak',
                    },
                    {
                        'id': 'n' + option.CheckId,
                        'text': 'no',
                        'translatedText': 'nej_tak',
                    }, 
                ]
            }
        },
        getOptionDefaultValue(option) {
            const yesChecked = (option.DefaultValue === '1') ? true : false
            const noChecked = (option.DefaultValue === '0') ? true : false

            var defaultValue = ''
            if (yesChecked) {
                defaultValue = 'yes'
            }
            else if (noChecked) {
                defaultValue = 'no'
            } else {
                if (option.Type === 'ONE_CHECK') {
                    defaultValue = 'no'
                }
            }
            return defaultValue
        },
        dataChanged(data) {
            this.selectedOptions[data['id']] = data['selectedOption']

            this.formatData()
            this.$forceUpdate()
        },
        nextButtonClicked() {
            var missingInput = false
            var needToUpdate

            for (let [checkID, selectedOption] of Object.entries(this.selectedOptions)) {
                if (selectedOption === '') {
                    if (this.filteredData[checkID].errorStyle !== 'display: block;') {
                        needToUpdate = true
                    }
                    this.filteredData[checkID].errorStyle = 'display: block;'
                    missingInput = true
                }else {
                    if (this.filteredData[checkID].errorStyle !== 'display: none;') {
                        needToUpdate = true
                    }
                    this.filteredData[checkID].errorStyle = 'display: none;'
                }
            }
            if (needToUpdate) this.$forceUpdate()
            if (missingInput) return

            var title = ""
            var data = {}
            for (const key in this.selectedOptions) {
                const id = this.filteredData[key].id
                const type = this.filteredData[key].type

                let optionTitleValue = ''
                if (type === 'CHECK') {
                    if (this.selectedOptions[key] === 'yes') {
                        data[`checks[${id}]`] = '1'
                    }
                    optionTitleValue = this.lang.company[(this.selectedOptions[key] == 'yes') ? "ja_tak" : "nej_tak"]
                } else if (type === 'ONE_CHECK') {
                    data[`radio${id}`] = this.selectedOptions[key]
                    optionTitleValue = this.lang.company[(this.selectedOptions[key] == true) ? "t" : "f"]
                } else if (type === 'TEXT') {
                    data[`checks[${id}]`] = encodeURI(this.selectedOptions[key].trim())
                    optionTitleValue = this.selectedOptions[key]
                }

                title += this.filteredData[key].name + " " + optionTitleValue + ", "
            }

            console.log(data)
            this.dataSelected({
                'title': title,
                'data': data
            })
        }
    },

}
</script> 