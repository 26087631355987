<template>
    <div >
        <div v-if="months.length <= 0"></div>

        <div v-else>
            <div class="month_buttons_div">
                <div style="display:inline-block; width: 49%;">
                    <span 
                        id="prev" 
                        class="left_month_btn month_buttons show_end_time ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-secondary" 
                        @click="previousMonthClicked"
                    >
                        <i class="month_btn_arrow fas fa-chevron-left"></i>
                    </span>
                </div>
                <div style="display:inline-block; width: 49%;">
                    <span 
                        id="next" 
                        class="right_month_btn month_buttons button_time show_end_time ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-secondary" 
                        @click="nextMonthClicked"
                    > 
                        <i style="" class="month_btn_arrow fas fa-chevron-right"></i>
                    </span>
                </div>
            </div>

            <div 
                id="datepicker_scroll_container" 
            >
                <div
                    id="datepicker"
                    class="hasDatepicker"    
                    style="display: block;"
                >
                    <div
                        class="ui-datepicker-inline ui-datepicker ui-widget ui-widget-content ui-helper-clearfix ui-corner-all ui-datepicker-multi"
                        style="display: block;"
                    >
                        <div class="ui-datepicker-header ui-widget-header ui-helper-clearfix ui-corner-left">
                            <div class="ui-datepicker-title">
                                <span class="ui-datepicker-month" style="color: black;">{{ getNewCurrentMonth }} </span> 
                                <span class="ui-datepicker-year" style="color: black;">{{ getCurrentYear }}</span>
                            </div>
                        </div>

                        <table class="ui-datepicker-calendar">
                            <thead>
                                <tr>
                                    <th
                                        v-for="(dayName, index) in getWeekDays()"
                                        :key="`${dayName}${index}`"
                                    >
                                        {{ dayName }}
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr
                                    v-for="(weekDates, weekNumber, index) in monthsWeeks[newCurrentMonthIndex]"
                                    :key="`${weekNumber}${index}`"
                                >
                                <!-- class="ui-datepicker-other-month ui-datepicker-unselectable ui-state-disabled" -->
                                    <td
                                        v-for="(dateObject, index) in weekDates"
                                        :class="dateObject.tdClass"
                                        :key="index"
                                        :title="dateObject.comment"
                                    >
                                    <!-- class="ui-state-default ui-button ui-widget ui-corner-all ui-button-text-only" -->
                                        <span v-if="dateObject.date === undefined"></span>
                                        <span 
                                            v-else-if="dateObject.locked"
                                            :class="dateObject.buttonClass"
                                            @click="onDateClicked(dateObject)"
                                        >
                                            <span class="ui-button-text">{{ dateObject.date.day }}</span>
                                            <span 
                                                v-if="dateObject.iconClass !== ''"
                                                :class="dateObject.iconClass"
                                            >
                                            </span>
                                        </span>

                                        <a 
                                            v-else
                                            :class="dateObject.buttonClass"
                                            @click="onDateClicked(dateObject)"
                                        >
                                            <span class="ui-button-text">{{ dateObject.date.day }}</span>
                                            <span 
                                                v-if="dateObject.iconClass !== ''"
                                                :class="dateObject.iconClass"
                                            >
                                            </span>
                                        </a>
                                        <!-- class="ui-state-default ui-button ui-widget ui-corner-all ui-button-disabled ui-state-disabled ui-button-text-only"  -->
                                        
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script lang="js">

import {MyDate, CalendarGenerator} from '../../models/CalendarGenerator';

export default {
    name: 'SelectDate',
    data() {
        return {
            newCurrentMonthIndex: 0,
            months: [],
            monthsWeeks: [],
            showModal: false
        }
    },
    props: ['data', 'dataSelected'],
    watch: {
        data(oldVal, newVal) {
            if (oldVal !== newVal) {
                this.readData()

                const isConceptSelected = this.$store.getters.isConceptSelected 
                if (isConceptSelected) {
                    this.applyConceptSelectedFeatures()
                }
            }
        }
    },
    computed: {
        getNewCurrentMonth() {
            const monthNamesKeyTranslated = ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"]
            const myMonth = this.months[this.newCurrentMonthIndex]
            const monthKey = monthNamesKeyTranslated[myMonth.monthIndex]
            const monthName = this.lang.company[monthKey]
            return monthName
        },
        getCurrentYear() {
            const myMonth = this.months[this.newCurrentMonthIndex]
            return myMonth.year
        },
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        getFirstAndLastDates(dates) { 
            if (dates.length == 0) {
                return {'first': undefined, 'second': undefined}
            }
            const firstDate = MyDate.convertFromString({dateStr:dates[0], separator:"-"})
            const lastDate =  MyDate.convertFromString({dateStr:dates[dates.length-1], separator:"-"})
            return {firstDate, lastDate}
        },
        readData() {
            const dates = Object.keys(this.data)
            const {firstDate, lastDate} = this.getFirstAndLastDates(dates)
            if (firstDate === undefined || lastDate === undefined) {
                return
            }

            const calendarGenerator = new CalendarGenerator(firstDate, lastDate)
            const myMonths = calendarGenerator.getMonths()
            this.monthsWeeks = new Array(myMonths.length)

            myMonths.forEach((month, monthIndex) => {
                this.months.push(month)

                const listOfWeeks = month.getListOfWeeks()
                const listOfWeeksFormatted = []
                listOfWeeks.forEach((week, weekIndex) => {
                    listOfWeeksFormatted.push(new Array(7))
                    week.forEach((date, dayIndex) => {
                        const dateUiData = this.getDateData(date) 
                        const formattedDay = {
                            'date': date,
                            'tdClass': dateUiData.tdClass,
                            'buttonClass': dateUiData.buttonClass,
                            'iconClass': dateUiData.iconClass,
                            'comment': dateUiData.comment,
                            'remove_continue_btn': dateUiData.remove_continue_btn,
                            'locked': dateUiData.locked
                        }
                        listOfWeeksFormatted[weekIndex][dayIndex] = formattedDay
                    })
                })
                this.monthsWeeks[monthIndex] = listOfWeeksFormatted 
            })
        },
        getDateData(date) {
            // td
            const tdLockedClass = "ui-datepicker-unselectable ui-state-disabled"
            const tdNotLockedClass = " restaurant-date-bookable"

            let result = {
                buttonClass: '',
                iconClass: '',
                tdClass: tdLockedClass,
                comment: '',
                remove_continue_btn: '0',
                locked: false
            }

            if (date === undefined) return result

            // Date class 
            const dateLockedClass = "ui-state-default ui-button ui-widget ui-corner-all ui-button-disabled ui-state-disabled ui-button-text-only"
            const dateLockedWithCommentClass = "ui-state-default ui-button ui-widget ui-corner-all ui-button-disabled ui-state-disabled ui-button-text-icon-secondary"
            const dateNotLockedClass = "ui-state-default ui-button ui-widget ui-corner-all ui-button-text-only"
            const dateTodayClass = "ui-state-default ui-state-highlight ui-button ui-widget ui-corner-all ui-button-text-only"
            const dateTodayWithCommentClass = "ui-state-default ui-state-highlight ui-button ui-widget ui-corner-all ui-button-text-icon-secondary"
            const dateSpecialClass = "ui-state-default ui-button ui-widget ui-corner-all ui-button-text-icon-secondary"

            let dateStr = date.toFormattedString()
            if (this.data.hasOwnProperty(dateStr)) {
                let dateObjectFromServer = this.data[dateStr]
                const nonBookable = dateObjectFromServer.non_bookable
                const hasComment = dateObjectFromServer.comment !== undefined && dateObjectFromServer.comment !== '' 
                const remove_continue_btn = dateObjectFromServer.remove_continue_btn
                if (nonBookable) {
                    result['locked'] = true
                    result['comment'] = dateObjectFromServer.non_bookable_text
                    result['remove_continue_btn'] = remove_continue_btn
                    if (hasComment) {
                        result['buttonClass'] = dateLockedWithCommentClass
                        result['iconClass'] = 'ui-button-icon-secondary ui-icon ui-icon-alert yellow ui-icon'
                    } else {
                        result['buttonClass'] = dateLockedClass
                    }
                } else if (hasComment) {
                    result['iconClass'] = 'ui-button-icon-secondary ui-icon ui-icon-info blue ui-icon'
                    result['comment'] = dateObjectFromServer.comment 
                    result['remove_continue_btn'] = remove_continue_btn
                    result['tdClass'] = tdNotLockedClass

                    if (date.isToday()) {
                        result['buttonClass'] = dateTodayWithCommentClass
                    } else {
                        result['buttonClass'] = dateSpecialClass
                    }
                } else {
                    result['tdClass'] = tdNotLockedClass
                    if (date.isToday()) {
                        result['buttonClass'] = dateTodayClass
                    } else {
                        result['buttonClass'] = dateNotLockedClass
                    }
                }
            } else {
                result['buttonClass'] = dateLockedClass
                result['locked'] = true
            }

            return result
        },
        applyConceptSelectedFeatures() {
            var numberOfSelectableDates = 0
            var lastSelectableDate = undefined
            var firstMonthIndexWithAtLeastOneSelectableDate = -1

            this.monthsWeeks.forEach((monthWeeks, monthIndex) => {
                monthWeeks.forEach((week) => {
                    week.forEach((day) => {
                        if (!day.locked && day.date) {
                            numberOfSelectableDates++
                            lastSelectableDate = day
                            
                            if (firstMonthIndexWithAtLeastOneSelectableDate == -1) {
                                firstMonthIndexWithAtLeastOneSelectableDate = monthIndex
                            }
                        }
                    })
                })
            })

            if (numberOfSelectableDates == 1 && lastSelectableDate) {
                this.onDateClicked(lastSelectableDate)
            }

            if (firstMonthIndexWithAtLeastOneSelectableDate != this.newCurrentMonthIndex) {
                this.newCurrentMonthIndex =  firstMonthIndexWithAtLeastOneSelectableDate
            }
        }, 
        getWeekDays() {
            return [
                this.lang.company["ma"],
                this.lang.company["ti"],
                this.lang.company["on"],
                this.lang.company["to"],
                this.lang.company["fr"],
                this.lang.company["loe"],
                this.lang.company["soe"],
            ]
        },

        previousMonthClicked() {
            this.newCurrentMonthIndex = (this.newCurrentMonthIndex + (this.months.length-1)) % this.months.length
        },
        nextMonthClicked() {
            this.newCurrentMonthIndex = (this.newCurrentMonthIndex + 1) % this.months.length
        },
        onDateClicked(dateObject) {
            if (dateObject.locked) {
                if (dateObject.comment !== '') {
                    this.$root.$emit('show-date-warning-modal', dateObject.comment)
                }
                return
            }
            const date = dateObject.date
            const dateStr = date.toFormattedString()

            this.dataSelected({
                'date': dateStr,
                'titleDate': `${date.day}/${date.month} ${date.year}`,
                'remove_continue_btn': dateObject.remove_continue_btn,
                'comment': dateObject.comment
            })
        },
    }
}
</script>

<style scoped>
.modal-mask {
    position: absolute;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}
.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}
</style>