<template>
    <div>
        <input 
            class="ui-helper-hidden-accessible" 
            type="radio"
            :name="text"
            :id="id"
            :value="text"
            :checked="isChecked"
            @change="onChange"
        />
        <label
            :class="labelClass"
            :for="id"
        >
            <span class="ui-button-text">
                <span class="text">{{ translatedText }}</span> 
                <div :style="iconDisplay">
                    <span class="ui-icon ui-icon-check"></span>
                </div>
            </span>
        </label>
    </div>
</template>

<script lang="js">

export default {
    name: 'CustomRadioButton',
    data() {
        return {
            
        }
    },
    props: ['text', 'id', 'name', 'translatedText', 'value'],
    computed: {
        isChecked() {
            return this.value === this.text;
        },
        labelClass() {
            const unSelectedClass = 'ui-button ui-widget ui-state-default ui-button-text-only ui-corner-right ui-corner-left'
            return (this.isChecked) ? unSelectedClass + ' ui-state-active' : unSelectedClass
        },
        iconDisplay() {
            return (this.isChecked) ? 'display:inline-block;' : 'display:none;'
        },
    },
    methods: {
        onChange() {
            this.$emit('input', this.text);
        }
    }, 
}
</script>