<template>
    <div style="text-align: center;">
        <p>{{ lang.company['vaelg_tidspunkt_beskrivelse'] }}</p>
        <div v-if="hasTimes" id="time_buttons" style="width:auto;">

            <button
                v-for="(timeData, index) in filteredData"
                :key="index"
                :title="timeData.title"
                :class="timeData.class"
                @click="onDateSelected(timeData)"
            >
                <span class="ui-button-text">
                    {{ timeData.time }} 
                </span>
                <span :class="timeData.iconCls"></span>
            </button>

        </div>
        <div v-else v-html="noAvailableTimeMessage"></div>


        <div v-if="hasRelatedRestaurants && !showRelatedRestaurants">
            <br>
            <p>
                {{ this.lang.company['kan_du_ikke_finde_det_ønskede_tidspunkt?_klik_her_for_at_se_om_der_er_ledigt_på_en_af_vores_øvrige_restauranter.'] }}
            </p>

            <a 
                class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
                role="button"
            >
                <span class="ui-button-text" @click="showRelatedRestaurants = true"> 
                    {{ lang.company['show_diffrent_resturants'] }}
                </span>
            </a>
        </div>
        <div v-if="hasRelatedRestaurants && showRelatedRestaurants">
            <div v-for="(restaurant, index) in this.restaurantData.related_restaurants" :key="restaurant.ResturantNo">
                <br>
                {{ restaurant.RESTAURANT_NAVN }} <br>
                {{ restaurant.RESTAURANT_ADRESSE }} <br>
                {{ restaurant.RESTAURANT_POSTNR }} {{ restaurant.City }} <br>
                <a 
                    class="ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                    role="button"
                    :href='"/onlinebooking?restaurantId=" + restaurant.ResturantNo'
                >
                    <span class="ui-button-text" > 
                        {{ lang.company['go_to_this_resturant'] }}
                    </span>
                </a>

            </div>
        </div>
    </div>
</template>

<script lang="js">



export default {
    name: 'SelectTime',
    data() {
        return {
            showRelatedRestaurants: false
        }
    },
    props: ['data', 'restaurantData', 'dataSelected'],
    methods: {
        onDateSelected(timeData) {
            if (timeData.clickable) {
                this.dataSelected({
                    'time': timeData.time,
                    'message': timeData.message,
                    'reservationLength': timeData.reservationLength,
                    'availableLengths': timeData.availableLengths
                })
            }
        }
    },
    computed: {
        hasTimes() {
            return this.data.length != 0
        },
        hasRelatedRestaurants() {
            return this.restaurantData.related_restaurants && this.restaurantData.related_restaurants.length > 0
        },
        lang() {
            return this.$store.state.lang
        },
        noAvailableTimeMessage() {
            const commentJson = JSON.parse(this.restaurantData.texts['no_online_times_available'])
            return commentJson[this.lang.name]
        },
        filteredData() {
            var result = {}
            var index = 0

            for (const key in this.data) {
                const timeData = this.data[key]

                var message = ''
                var title = ''
                var cls = ''
                var iconCls = ''
                var clickable = true
                var requireEvent = 0


                const inEvent = timeData.InEvents !== undefined && timeData.InEvents.length > 0
                if (inEvent) {
                    var eventMessage = 'An event is held at this time<br><br>'
                    var eventFound = false

                    timeData.InEvents.forEach((key, value) => {
                        eventMessage += value.title_external + '<br>'
                        eventMessage += `<a href="https://bord-booking.dk/online_events.php?company=`+this.restaurantData['company_id']+`&event='${value.id}'">Go to booking</a><br><br>`
                        eventFound = true
                    })
                    if (eventFound) {
                        if (timeData.NonBookable === 0) {
                            message = value.Message + eventMessage
                        }else {
                            message = eventMessage
                            requireEvent = 1
                        }
                    }
                }

                const confirmTimeMessageJson = (timeData.ConfirmTimeMessages === '') ? [] : JSON.parse(timeData.ConfirmTimeMessages)
                const confirmTimeMessage = (confirmTimeMessageJson.length === 0) ? '' : confirmTimeMessageJson[0].MessageToGuest

                if (timeData.NonBookable) {
                    cls = 'button_time time_non_bookable ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-secondary disabled'
                    iconCls = 'ui-button-icon-secondary ui-icon ui-icon-alert'
                    clickable = false
                    title = timeData.Message
                }else if (timeData.ShowEndTime) {
                    cls = 'button_time show_end_time ui-button ui-widget ui-state-default ui-corner-all ui-button-text-icon-secondary'
                    iconCls = 'ui-button-icon-secondary ui-icon ui-icon-info blue'
                    title = timeData.Message
                    message = (confirmTimeMessage === '') ? timeData.Message : confirmTimeMessage
                }else {
                    cls = 'button_time ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only'
                    message = confirmTimeMessage
                }

                result[index] = {
                    time: timeData.value,
                    class: cls,
                    iconCls: iconCls,
                    clickable: clickable,
                    message: message,
                    title: title,
                    reservationLength: timeData.MaxLength,
                    availableLengths: timeData.AvailableLengths
                }
                index++
            }
            return result
        }
    }
}
</script>