<template>
    <div>
        <p>{{ selectAreaDescription }}</p>  
        <p>{{ restaurantData.texts.area_select_description['da-dk'] }}</p>

        <div id="area_after_time_buttons">
            <!-- button_area_after_time ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only -->

            <button
                class=" button_area_after_time ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only"
                v-for="(data, index) in data"
                    :key="index"
                    @click="areaSelected(data.Upstairs, data.area_confirm_text)"
            >
                <span class="ui-button-text">{{ data.Upstairs }}</span>
            </button>

        </div>
    </div>
</template>

<script lang="js">

export default {
    name: 'SelectArea',
    data() {
        return {

        }
    },
    props: ['data', 'dataSelected', 'restaurantData'],
    computed: {
        selectAreaDescription() {
            const lang = this.$store.state.lang.name
            const jsonData = JSON.parse(this.restaurantData.texts.area_select_description)
            return jsonData[lang]
        }
    },
    methods: {
        areaSelected(area, area_confirm_text) {
            this.dataSelected({
                'title': area,
                'area': area,
                'area_confirm_text': area_confirm_text
            })

        }
    }
}
</script>