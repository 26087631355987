//i18n-setup.js
import Vue from 'vue'
import VueI18n from 'vue-i18n'
import daLocaleMessages from './locales/da-dk.json'
import axios from 'axios'
//import router from './router'

const messages = { da: daLocaleMessages };
/* const messages = {
    dashboard: 'Dash',
    dk: {
        dashboard: 'Hello World'
    },
    en: {
        dashboard: 'Hello World'
    },
} */

Vue.use(VueI18n)

export const i18n = new VueI18n({
    //legacy: false,
    //globalInjection: true,
  locale: 'da-dk', // set locale
  fallbackLocale: 'da-dk',
  messages // set locale messages
})

const loadedLanguages = ['da-dk'] // our default language that is preloaded

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `./locales/${lang}.json`).then(
    messages => {
      i18n.setLocaleMessage(lang, messages.default)
      loadedLanguages.push(lang)
      return setI18nLanguage(lang)
    }
  )
}