<template>
    <div>

        <p>{{ question }}</p>
        
        <div
            v-for="(reservationLength, index) in filteredData"
            :key="index"
        >
            <br />
            <br />
            <b>{{ reservationLength.title }}</b>
            <br />
            {{ reservationLength.messageToGuest }}
            <br />

            <CustomRadioButton
                class="button_reservation_length ui-buttonset"
                :id="index"
                :name="index + 'a'"
                :text="reservationLength.title"
                :translatedText="lang.company['select']"
                v-model="pickedTime"
            />
        </div>


        <span 
            class="button_confirmOptions ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="dateConfirmed"
        >
            <span class="ui-button-text">{{ lang.company['videre'] }}</span>
        </span>
    </div>
</template>

<script lang="js">

import CustomRadioButton from '../customButtons/CustomRadioButton.vue';

export default {
    name: 'ConfirmTime',
    data() {
        return {
            pickedTime: -1,
            timeToMessage: {}, // key=time, value=MessageToGuest,
            timeToReservationLength: {}
        }
    },
    props: ['data', 'restaurantData', 'dataSelected'],
    components: {
        CustomRadioButton
    },
    computed: {
        lang() {
            return this.$store.state.lang
        },
        translatedTimerText() {
            return this.lang.company['timer']
        },
        question() {
            return (this.data.availableLengths.length == 0) ? this.data.message : this.lang.company['select_available_length_of_booking']
        },
        filteredData() {
            console.log(this.data)
            var result = {}

            for (const index in this.data.availableLengths) {
                const value = this.data.availableLengths[index]
                const reservationLength = value.ReservationLength

				const hours = Math.floor((reservationLength) / 60, 2);
				var minutes = (reservationLength) % 60;
                var minutesText = ""
                if (minutes !== 0) {
                    if (minutes == '0') {
                        minutesText = ` 00 ${this.lang.default['minutes']}`
                    } else {
                        minutesText = ` ${minutes} ${this.lang.default['minutes']}`
                    }
                }

                result[index] = {
                    'title': `${hours} ${this.lang.default['hours']}${minutesText}`,
                    'time': hours + ':' + minutes,
                    'reservationLength': reservationLength,
                    'messageToGuest': this.data.availableLengths[index].MessageToGuest
                }
                this.timeToMessage[result[index].title] = result[index].messageToGuest
                this.timeToReservationLength[result[index].title] = reservationLength
            }
            
            if (Object.keys(result).length > 0) {
                this.pickedTime = result[0].title
            }
            return result
        }
    },
    methods: {
        dateConfirmed() {
            if (this.data.availableLengths.length == 0) {
                this.dataSelected({
                    'title': this.data.message,
                    'message': this.data.message
                })
            }else {
                const pickedReservationLength = this.timeToReservationLength[this.pickedTime]
                if (pickedReservationLength === undefined) {
                    console.log('picked reservation length is undefined! Option is not selected')
                }else {
                    const mesageToGuest = this.timeToMessage[this.pickedTime]
                    this.dataSelected({
                        'time': pickedReservationLength,
                        'title': this.pickedTime + ": " + mesageToGuest,
                        'message': this.data.message,
                        'mesageToGuest': mesageToGuest,
                        'reservationLength': pickedReservationLength * 60
                    })
                }
            }
        }
    },
}
</script>