<template>
    <div>
        <p>{{ lang.company['select_concept_description'] }}</p>
        <div 
            class="concept_buttons"
            v-for="(conceptData, index) in data"
            :key="index"
            :id="`concept_`+conceptData.id"
        >
            <div
                class="concept_container"    
            >
                <div class="image">
                    <img :src="conceptData.image_url" alt="Image" class="concept-image"/> 
                </div>
                <div class="text_container">
                    <h2 class="concept_title">{{ conceptData.name }}</h2>
                    <p class="concept_description" v-html="formatMessage(conceptData.description)"></p>
                </div>
            </div>

            <span v-if="conceptData.is_concept == 1"
                class="select_concept ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
                role="button" 
                aria-disabled="false"
                @click="conceptSelected(conceptData.id, conceptData.name)"
            >
                <span class="ui-button-text">{{ lang.company['videre'] }}</span> 
            </span>
            <span v-if="conceptData.is_event == 1"
                class="select_concept ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
                role="button" 
                aria-disabled="false"
                @click="conceptSelectEvent(conceptData.id, conceptData.name)"
            >
                <span class="ui-button-text">{{ lang.company['videre'] }}</span> 
            </span>
        
            <div class="clearfix"></div>
            <hr v-if="index < data.length-1">

        </div>

        <br>

        <span  v-if="removeDefaultBookingBtn(data) == 0"
            class="select_concept ui-button ui-widget ui-state-default ui-corner-all ui-button-text-only" 
            role="button" 
            aria-disabled="false"
            @click="conceptSelected(0, lang.company['no_concept_selected'])"
        >
            <span class="ui-button-text">{{ lang.company['continue_normal_booking'] }}</span> 
        </span>
    </div>
</template>

<script lang="js">


export default {
    name: 'SelectConceptComponent',
    data() {
        return {
        }
    },
    props: ['data', 'dataSelected', 'restaurantData'],
    computed: {
        lang() {
            return this.$store.state.lang
        },
    },
    methods: {
        removeDefaultBookingBtn(data){
            for (let i = 0; i < data.length; i++) {
                if(data[i].remove_continue_btn == 1){
                    return 1;
                }
            }
            return 0;
        },
        formatMessage(msg) {
            return msg.replace(/\n/g, '<br>')
        },
        conceptSelected(id, name) {
            this.dataSelected({
                'concept_id': id,
                'concept_name': name,
            })
        },
        conceptSelectEvent(id, name) {
            document.location.href = 'https://bord-booking.dk/online_events.php?company='+this.restaurantData['company_id']+'&event='+id;
        }
    },
}
</script>


<style>
.concept_container {
    display: flex;
    
}

.image {
  flex: 0 0; 
}

.concept-image {width:150px;}

.text_container {
  flex: 1;
  /* padding-left: 20px; */
  padding: 0 0 0 20px;
  text-align: left;
  word-break: break-word;
}

.concept_title {
  font-weight: bold;
  font-size: 1.5em;
}

.concept_description {
  margin-top: 10px;
}

.clearfix::after {
  content: '';
  display: table;
  clear: both;
}

@media screen and (max-width: 450px) {
    .concept-image {width:100px;}

    .concept_title {
  font-size: 1.2em;
}


}
</style>


