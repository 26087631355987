<template>
    <RouterView :key="key"/>
</template>


<script lang="js">
import { _, RouterView } from 'vue-router'
import login from '@/views/onlinebooking.vue';


export default {
    name: 'App',

    data() {
        return {
        }
    },

    methods: {
        
    },

    computed: {
        key() {
            const restaurantID = this.$route.query.hasOwnProperty('restaurantId') ? this.$route.query.restaurantId : ''
            const domain = window.location.host
            const lang = this.$route.query.hasOwnProperty('lang') ? this.$route.query.lang : ''
            const del = this.$route.query.hasOwnProperty('delete') ? this.$route.query.delete : ''
            const newKey = domain + restaurantID + lang + del
            return newKey
        }
    },

    components: {
        'login': login,
    },

    mounted() {
    },

    created() {
        //
    }
}
</script>

